body {
  margin: 0;
  font-family: 'Old Standard TT', serif;
  background-color: whitesmoke;
}

.sidebar {
  padding: 20px;
  display: flex;
  flex-direction: row; 

  img {
    width: 40px; 
    height: 40px; 
    
  }
}

.nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  font-weight: bold;
  font-size: 1.2rem;

}

.nav li {
  margin-right: 40px;
  margin-top: 10px;
}

.nav a {
  text-decoration: none; 
  color: black; 
}



.footer {
  background-color: #333;
  color: white;
  padding: 50px;
}

.footer-columns {
  display: flex;
  justify-content: space-around;
}

.footer-column {
  flex: 1;
  margin-right: 20px;
}

.footer-column h3 {
  margin-bottom: 10px;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 5px;
}

.footer-links a {
  color: white;
  text-decoration: none;
}

.home-page {
  text-align: center;
}


.banner audio {
  margin-top: 10px;
}

.subtitle {
  margin-top: 10px;
}

.image-container {
  margin: 20px 0;
}

.image-container img {
  width: 100%;
  height: auto;
}

figcaption {
  text-align: center; 
  font-style: italic; 
  margin-top: 10px; 
}

p{
  text-align: center;
  margin:0;
  padding-top: 20px;
}


/* Lexique */
.lexique-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.lexique-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px; /* Ajout de marge en bas du tableau */
}

.lexique-table th,
.lexique-table td {
  border: 2px solid black;
  padding: 8px;
}

.alphabet-container {
  margin-top: 20px;
}

.alphabet-letter {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin-right: 5px;
  cursor: pointer;
}

.alphabet-letter.selected {
  background-color: #007bff;
  color: #fff;
}

h1{
  font-size: 3rem;
}

h2{
  margin-bottom: 3rem;
  font-size: 3rem;
}

.apropos-container {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.image-gauche {
  width: 100%;
  height: auto;
}

.apropos-content {
  display: flex;
  flex-direction: column;
}

.image-ronde {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  margin-left: 5rem;
}





.text-container {
  font-size: 18px; /* Ajustez la taille du texte selon vos préférences */
  line-height: 1.5; /* Ajustez l'espacement entre les lignes du texte */
  white-space: pre-line; /* Conserve les sauts de ligne dans le texte */
  margin-top: 20px; /* Ajoutez un espacement en haut du bloc de texte */
}

.intro {
  font-size: 24px; /* Ajustez la taille du texte selon vos préférences */
}

.signature {
  font-style: italic;
  text-align: right;
  font-size: 16px; /* Ajustez la taille du texte selon vos préférences */
  margin-top: 20px; /* Ajoutez un espacement en haut du paragraphe de la signature */
}


.side-by-side {
  display: flex;
}


.carousel-item img {
  width: 100%; 
}

.map-container {
  margin-top: 20px;
  margin-right: 30px; /* Espacement entre les deux cartes */
}

.map-container iframe {
  margin-top: 10px; /* Espacement entre la carte et le texte en dessous */
}

.map-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.carousel-container {
  margin: 0 auto; /* Centrer le carrousel horizontalement */
  width: 50%; /* Définir une largeur pour le carrousel */
}

.carousel-container .slick-slider {
  margin: 0 auto; /* Centrer le slider horizontalement */
}

.carousel-container .slick-slide img {
  max-width: 100%; /* Ajuster la largeur de l'image pour qu'elle ne dépasse pas la taille du conteneur */
  height: auto; /* Ajuster la hauteur de l'image de manière proportionnelle */
  margin: 0 auto; /* Centrer l'image horizontalement */
}





/* .container {
  margin: 0 auto;
  width: 100%;
  height: 80vh;
  border-color: white;
} */


/* App.css ou votre fichier CSS séparé */

/* App.css ou votre fichier CSS séparé */



.riverain-item:hover {
  background-color: #ddd; /* Couleur de fond plus foncée au survol */
}





/* App.css ou votre fichier CSS séparé */

.riverain-container {
  display: flex;
  flex-direction: column; /* Aligner les éléments en colonne */
  align-items: center;
  width: 100%;
}

.image-container {
  margin-bottom: 20px; /* Espacement entre l'image et les boutons */
}

.riverain-image {
  width: 700px; /* Ajustez la largeur de l'image selon vos besoins */
  height: auto;
  margin-top: 20px; /* Laissez la hauteur auto pour conserver les proportions */
}

.riverain-buttons {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;

}

.riverain-item {
  display: inline-block;
  margin: 10px;
  padding: 10px 20px;
  background-color: #f0f0f0;
  color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.riverain-item:hover {
  background-color: #ddd;
}

/* App.css */

/* Couleur des liens */
.riverain-item a {
  color: black;
  text-decoration: none; 
}

/* Suppression du soulignement */
.riverain-item a:hover,
.riverain-item a:focus,
.riverain-item a:active {
  text-decoration: none; /* Supprimer le soulignement */
}


.riverain-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Pour aligner les éléments horizontalement */
}

.image-container2 {
  max-width: 700px; /* Limiter la largeur de l'image */
  margin-bottom: 20px; /* Espacement entre l'image et le texte */
}

.riverain-image {
  width: 100%; /* Pour s'assurer que l'image occupe toute la largeur du conteneur */
}


.apropos-content {
  max-width: 700px; /* Limiter la largeur du bloc de texte */
  text-align: left; /* Aligner le texte à gauche */
}

.apropos-content h4 {
  font-size: 1.5em; /* Ajuster la taille du titre h4 */
}

.apropos-content p {
  font-size: 1.2em; /* Ajuster la taille du texte p */
  line-height: 1.5; /* Ajuster l'espacement entre les lignes */
}

.histoire{
  text-align: left;
  margin-bottom: 50px;
}

